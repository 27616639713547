import style from '../css/styles.scss'
importAll(require.context('../images/', true, /\.png$/));

// Calendrier
//import './calendar-settings'

// Application Vue
//const Component = () => render(`<div class="${style.app}"></div>`)
//import './vue/app'

// Statistiques
//import './stats'

// Formulaires sur popup modale

//region Fonts
//@import "/fonts/cadman_bolditalic/stylesheet.css";
//@import "/fonts/cadman_italic/stylesheet.css";
import "../fonts/cadman_regular/stylesheet.css"
//@import "/fonts/cadman_bold/stylesheet.css";
//@import "/fonts/acme_regular/stylesheet.css";


function importAll (r) {
   r.keys().forEach(r);
}